$(function(){

	//console.log("Here we go!", "Booting up!");

	$('.gallery').flickity({
		wrapAround: true,
		contain: true,
		imagesLoaded: true,
		pageDots: false,
		cellAlign: 0.0
	});

	// var options = { videoId: 'vL676wKDqnk',repeat: true };
	// $('.background-video').tubular(options);

});